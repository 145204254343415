// import {
//     Box,
//     Button,
//     ButtonGroup,
//     Flex,
//     HStack,
//     IconButton,
//     Input,
//     SkeletonText,
//     Text,
//   } from '@chakra-ui/react'
//   import { FaLocationArrow, FaTimes } from 'react-icons/fa'
  
//   import {
//     useJsApiLoader,
//     GoogleMap,
//     Marker,
//     Autocomplete,
//     DirectionsRenderer,
//   } from '@react-google-maps/api'
//   import { useRef, useState } from 'react'
  
//   const center = { lat: 48.8584, lng: 2.2945 }
  
//   function App1() {
//     const { isLoaded } = useJsApiLoader({
//       googleMapsApiKey: "AIzaSyB249oWvDs5DTvKrtvDstCQ-IJC4uDpWkg",
//       libraries: ['places'],
//     })
  
//     const [map, setMap] = useState(/** @type google.maps.Map */ (null))
//     const [directionsResponse, setDirectionsResponse] = useState(null)
//     const [distance, setDistance] = useState('')
//     const [duration, setDuration] = useState('')
  
//     /** @type React.MutableRefObject<HTMLInputElement> */
//     const originRef = useRef()
//     /** @type React.MutableRefObject<HTMLInputElement> */
//     const destiantionRef = useRef()
  
//     if (!isLoaded) {
//       return <SkeletonText />
//     }
  
//     async function calculateRoute() {
//       if (originRef.current.value === '' || destiantionRef.current.value === '') {
//         return
//       }
//       // eslint-disable-next-line no-undef
//       const directionsService = new google.maps.DirectionsService()
//       const results = await directionsService.route({
//         origin: originRef.current.value,
//         destination: destiantionRef.current.value,
//         // eslint-disable-next-line no-undef
//         travelMode: google.maps.TravelMode.DRIVING,
//       })
//       setDirectionsResponse(results)
//       setDistance(results.routes[0].legs[0].distance.text)
//       setDuration(results.routes[0].legs[0].duration.text)
//     }
  
//     function clearRoute() {
//       setDirectionsResponse(null)
//       setDistance('')
//       setDuration('')
//       originRef.current.value = ''
//       destiantionRef.current.value = ''
//     }
  
//     return (
//       <Flex
//         position='relative'
//         flexDirection='column'
//         alignItems='center'
//         h='100vh'
//         w='100vw'
//       >
//         <Box position='absolute' left={0} top={0} h='100%' w='100%'>
//           {/* Google Map Box */}
//           <GoogleMap
//             center={center}
//             zoom={15}
//             mapContainerStyle={{ width: '100%', height: '100%' }}
//             options={{
//               zoomControl: false,
//               streetViewControl: false,
//               mapTypeControl: false,
//               fullscreenControl: false,
//             }}
//             onLoad={map => setMap(map)}
//           >
//             <Marker position={center} />
//             {directionsResponse && (
//               <DirectionsRenderer directions={directionsResponse} />
//             )}
//           </GoogleMap>
//         </Box>
//         <Box
//           p={4}
//           borderRadius='lg'
//           m={4}
//           bgColor='white'
//           shadow='base'
//           minW='container.md'
//           zIndex='1'
//         >
//           <HStack spacing={2} justifyContent='space-between'>
//             <Box flexGrow={1}>
//               <Autocomplete>
//                 <Input type='text' placeholder='Origin' ref={originRef} />
//               </Autocomplete>
//             </Box>
//             <Box flexGrow={1}>
//               <Autocomplete>
//                 <Input
//                   type='text'
//                   placeholder='Destination'
//                   ref={destiantionRef}
//                 />
//               </Autocomplete>
//             </Box>
  
//             <ButtonGroup>
//               <Button colorScheme='pink' type='submit' onClick={calculateRoute}>
//                 Calculate Route
//               </Button>
//               <IconButton
//                 aria-label='center back'
//                 icon={<FaTimes />}
//                 onClick={clearRoute}
//               />
//             </ButtonGroup>
//           </HStack>
//           <HStack spacing={4} mt={4} justifyContent='space-between'>
//             <Text>Distance: {distance} </Text>
//             <Text>Duration: {duration} </Text>
//             <IconButton
//               aria-label='center back'
//               icon={<FaLocationArrow />}
//               isRound
//               onClick={() => {
//                 map.panTo(center)
//                 map.setZoom(15)
//               }}
//             />
//           </HStack>
//         </Box>
//       </Flex>
//     )
//   }
  
//   export default App1
  


//   React.StrictMode>
//     <ChakraProvider theme={theme}>
//       <App />
//     </ChakraProvider>
//   </React.StrictMode>



import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Input,
  Paper,
  Typography,
  Grid,
} from '@mui/material';
import { FaLocationArrow, FaTimes } from 'react-icons/fa';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api';

const center = { lat: 48.8584, lng: 2.2945 };

function App1() {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyB249oWvDs5DTvKrtvDstCQ-IJC4uDpWkg",
    libraries: ['places'],
  });

  const [map, setMap] = useState(null);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');

  const originRef = useRef();
  const destinationRef = useRef();

  if (!isLoaded) {
    return <Typography>Loading...</Typography>;
  }

  async function calculateRoute() {
    if (originRef.current.value === '' || destinationRef.current.value === '') {
      return;
    }
    const directionsService = new window.google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destinationRef.current.value,
      travelMode: window.google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setDistance('');
    setDuration('');
    originRef.current.value = '';
    destinationRef.current.value = '';
  }

  return (
    <Box position="relative" height="100vh" width="100vw">
      {/* Google Map Container */}
      <GoogleMap
        center={center}
        zoom={15}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
        onLoad={(map) => setMap(map)}
      >
        <Marker position={center} />
        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
      </GoogleMap>

      {/* Controls Panel */}
      <Paper
        elevation={3}
        sx={{
          position: 'absolute',
          top: 16,
          left: '50%',
          transform: 'translateX(-50%)',
          padding: 2,
          width: 400,
          zIndex: 10,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={5}>
            <Autocomplete>
              <Input fullWidth placeholder="Origin" inputRef={originRef} />
            </Autocomplete>
          </Grid>
          <Grid item xs={5}>
            <Autocomplete>
              <Input fullWidth placeholder="Destination" inputRef={destinationRef} />
            </Autocomplete>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={clearRoute}>
              <FaTimes />
            </IconButton>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="space-between" mt={2}>
            <Button variant="contained" color="primary" onClick={calculateRoute}>
              Calculate Route
            </Button>
            <IconButton
              color="primary"
              onClick={() => {
                map.panTo(center);
                map.setZoom(15);
              }}
            >
              <FaLocationArrow />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Distance: {distance}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Duration: {duration}</Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default App1;
